const state = {
  extUser: null,
  authenticated: false,
  currentUser: false,
  currentContractProcess: false,
  currentProcess: false,
  currentProcessAsociated: false,
  allCycles: null,
  currentCycle: null,
  currentContracts: false,
  menu: false,
  error: {
      status: false,
      typer: 0
  },
  loading: 0,
  currentInstrument: null,
  permissions: null
}

export default state;
