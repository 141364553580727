import axios from 'axios'
import { URI, HTTP_STATUS } from './app_constants'
import store from './store'
import router from "./router/index";
const VAPI = axios.create({
	baseURL: URI,
	timeout: 120000,
})

VAPI.defaults.withCredentials = true;

VAPI.interceptors.request.use(function (config) {
	if (store.getters.getCurrentUser) {
		config.headers.Authorization = store.getters.getCurrentUser.authorization
	}
	if (store.getters.getExtUser) {
		config.headers.External = store.getters.getExtUser.token
	}
	store.dispatch('setLoading');
	return config
}, err => {
	console.log(err)
	return Promise.reject(err)
})

VAPI.defaults.headers = {
	'Cache-Control': 'no-cache',
	'Pragma': 'no-cache',
	'Expires': '0'
};

VAPI.interceptors.response.use(function (response) {
	setTimeout(() => store.dispatch('clearLoading'), 1000)
	return Promise.resolve(response)
}, function (error) {
	store.dispatch('clearLoading');
	console.log(error)
	if (error.response.status == HTTP_STATUS.UNAUTHORIZED && !store.getters.getExtUser) store.dispatch('logOut')
	if (error.response) {
		return Promise.reject(error);
	}
})

export default VAPI