<template>
  <div id="app">
    <router-view />
    <Teleport to="#modal">
      <b-overlay :show="isLoading" no-wrap rounded :opacity="1" :blur="'40px'" :bg-color="'#17344B'">
        <template #overlay>
          <span class="loader">
            <img class="logo" src="@/assets/v_new.svg" alt="Evalua" />
          </span>
          <span class="loader-message">
            {{ $t('util.load') }}
          </span>

        </template>
      </b-overlay>
    </Teleport>
  </div>
</template>

<script>
import Teleport from 'vue2-teleport';
export default {
  components: {
    Teleport,
  },
  data() {
    return {
      messages: ["El éxito no es el final, el fracaso no es fatal: lo que cuenta es el coraje para seguir adelante",

        "La perseverancia es la clave para alcanzar grandes logros. ¡Sigue adelante, estás cada vez más cerca!",

        "Recuerda que cada paso hacia adelante te acerca a tu meta. ¡No te rindas!",

        "Los grandes logros requieren tiempo y esfuerzo. ¡Sigue adelante y prepárate para alcanzar nuevas alturas!",

        "La paciencia y la persistencia son cualidades de los triunfadores. ¡Mantén la calma y espera con determinación!",

        "La carga puede parecer pesada, pero los resultados valdrán la pena. ¡Sigue adelante con determinación!",

        "Recuerda que cada obstáculo superado te hace más fuerte y te acerca a tus metas. ¡No te detengas ahora!",

        "La espera puede ser desafiante, pero los logros que te esperan al final valdrán la pena cada segundo",

        "El progreso no siempre es lineal, pero cada pequeño paso cuenta. ¡Sigue avanzando y llegarás a la meta!",

        "Mientras esperas, recuerda que estás un paso más cerca de alcanzar tu objetivo. ¡Sigue adelante con optimismo!"]
    }
  },
}
</script>

<style lang="scss">
#app {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}
.loader-message{
  position: absolute;
  bottom: calc(10% - 200px);
  left: calc(50% - 250px );
  color: #fff;
  width: 500px;
  font-size: 1.4rem;
  text-align: center;
}
</style>
