function isAuthenticated(state) {
	state.authenticated = localStorage.getItem('authenticated') ? true : false;
	return state.authenticated;
}

function getCurrentUser(state) {
	if (!state.currentUser) {
		state.currentUser =
			localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null;
	}
	return state.currentUser;
}

function getError(state) {
	return state.error
}

function isLoading(state) {
	return state.loading;
}

function getCurrentContract(state) {
	if (state.currentContractProcess != null && !state.currentContractProcess.contract) {
		state.currentContractProcess = localStorage.getItem('currentContractProcess') ? JSON.parse(localStorage.getItem('currentContractProcess')) : null;
	}
	return state.currentContractProcess == null || state.currentContractProcess.contract == undefined ? false : state.currentContractProcess.contract
}

function getProcesses(state) {
	if (!state.currentContractProcess) {
		state.currentContractProcess = localStorage.getItem('currentContractProcess') ? JSON.parse(localStorage.getItem('currentContractProcess')) : null;
	}
	if(state.currentContractProcess != null)
		return state.currentContractProcess == null || state.currentContractProcess.processes == undefined ? false : state.currentContractProcess.processes
	else
		return null	

}
function getContracts(state){
	if (!state.currentContracts) {
		state.currentContracts = localStorage.getItem('currentContracts') ? JSON.parse(localStorage.getItem('currentContracts')) : null;
	}
	return state.currentContracts
}

function getCurrentProcess(state) {
	if (!state.currentProcess) {
		state.currentProcess = localStorage.getItem('currentProcess') ? JSON.parse(localStorage.getItem('currentProcess')) : null;
	}
	return state.currentProcess
}

function getCurrentProcessAsocited(state) {
	if (!state.currentProcessAsociated) {
		state.currentProcessAsociated = localStorage.getItem('currentProcessAsocited') ? JSON.parse(localStorage.getItem('currentProcessAsociated')) : null;
	}
	return state.currentProcessAsociated
}

function getMenu(state) {
	if (!state.menu) {
		state.menu = localStorage.getItem('menu') ? JSON.parse(localStorage.getItem('menu')) : null;
	}
	return state.menu
}

function getExtUser(state){
	if(state.extUser == null){
		state.extUser = localStorage.getItem('extUser') ? JSON.parse(localStorage.getItem('extUser')) : null;
	}
	return state.extUser
}
function getPermissions(state){
	if(state.permissions == null){
		state.permissions = localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions')) : null;
	}
	return state.permissions
}

function getCurrentInstrument(state){
	if(state.currentInstrument== null){
		state.currentInstrument = localStorage.getItem("currentInstrument") ? JSON.parse(localStorage.getItem("currentInstrument")) : null;
	}
	return state.currentInstrument
}

function getCurrentCycle(state){
	if(state.currentCycle == null){
		state.currentCycle = localStorage.getItem('currentCycle') ? JSON.parse(localStorage.getItem('currentCycle')) : null;
	}
	return state.currentCycle
}
function getAllCycles(state){
	if(state.allCycles == null){
		state.allCycles = localStorage.getItem('allCycles') ? JSON.parse(localStorage.getItem('allCycles')) : null;
	}
	return state.allCycles
}
export default {
	isLoading,
	getError,
	isAuthenticated,
	getCurrentUser,
	getCurrentContract,
	getProcesses,
	getCurrentProcess,
	getMenu,
	getExtUser,
	getContracts,
	getCurrentInstrument,
	getPermissions,
	getCurrentCycle,
	getAllCycles,
	getCurrentProcessAsocited
}