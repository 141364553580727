function setAuthenticated(state, payload) {
	state.authenticated = payload;
	if (payload) {
		localStorage.setItem("authenticated", payload);
	} else {
		localStorage.removeItem("authenticated");
	}
}

function setCurrentUser(state, payload) {
	state.currentUser = payload;
	if (payload) {
		localStorage.setItem("currentUser", JSON.stringify(payload));
	} else {
		localStorage.removeItem("currentUser");
	}
}
function setError(state, payload) {
	state.error = payload;
}

function increaseLoading(state) {
	state.loading += 1;
}
function decreaseLoading(state) {
	if (state.loading < 0) {
		state.loading = 0;
	} else {
		state.loading -= 1;
	}
}

function setContracts(state, payload) {
	state.currentContracts = payload;
	if (payload) {
		localStorage.setItem('currentContracts', JSON.stringify(payload));
	} else {
		localStorage.removeItem('currentContracts');
	}
}

function setContract(state, payload) {
	state.currentContractProcess = payload;
	if (payload) {
		localStorage.setItem('currentContractProcess', JSON.stringify(payload));
	} else {
		localStorage.removeItem('currentContractProcess');
	}
}

function selectCurrentProcess(state, payload) {
	state.currentProcess = payload;
	if (payload) {
		localStorage.setItem('currentProcess', JSON.stringify(payload));
	} else {
		localStorage.removeItem('currentProcess');
	}
}

function selectCurrentProcessAsociated(state, payload) {
	state.currentProcess = payload;
	if (payload) {
		localStorage.setItem('currentProcessAsociated', JSON.stringify(payload));
	} else {
		localStorage.removeItem('currentProcessAsociated');
	}
}

function setMenu(state, payload) {
	state.menu = payload;
	if (payload) {
		localStorage.setItem('menu', JSON.stringify(payload));
	} else {
		localStorage.removeItem('menu');
	}
}

function setExt(state, payload){
	state.extUser = payload;
	if (payload) {
		localStorage.setItem('extUser', JSON.stringify(payload));
	} else {
		//localStorage.removeItem('currentProcess');
		localStorage.removeItem('extUser');
	}
}
function setPermissions(state, payload){
	state.permissions = payload;
	if (payload) {
		localStorage.setItem('permissions', JSON.stringify(payload));
	} else {
		localStorage.removeItem('permissions');
	}
}

function setAllCycles(state, payload){
	state.allCycles = payload;
	if (payload) {
		localStorage.setItem('allCycles', JSON.stringify(payload));
	} else {
		localStorage.removeItem('allCycles');
	}
}
function setCycle(state, payload){
	state.currentCycle = payload;
	if (payload) {
		localStorage.setItem('currentCycle', JSON.stringify(payload));
	} else {
		localStorage.removeItem('currentCycle');
	}
}
export default {
	increaseLoading,
	decreaseLoading,
	setError,
	setCurrentUser,
	setAuthenticated,
	setContract,
	setContracts,
	selectCurrentProcess,
	setMenu,
	setExt,
	setPermissions,
	setCycle,
	setAllCycles,
	selectCurrentProcessAsociated
};
